<template>
   <div class="container">
                <div class="row">
                 <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2"><p class="c5"><span class="c2">Integracion Movil Terms &amp; Conditions</span></p><p class="c3"><span class="c2"></span></p><p class="c5"><span class="c2">End User License Agreement</span></p><p class="c3"><span class="c2"></span></p><p class="c5"><span class="c2">ATOMICAL SOFTWARE’S, LLC</span></p><p class="c3"><span class="c2"></span></p><p class="c5"><span class="c2">Effective date July 14 th 2021</span></p><p class="c3"><span class="c2"></span></p><p class="c5"><span class="c2">Integracion Movil  APP</span></p><p class="c6"><span class="c2"></span></p><p class="c8"><span class="c2">End-user = Merchant Installing Integracion Movil  SaaS Application on their Clover Device.</span></p><p class="c6"><span class="c2"></span></p><ol class="c7 lst-kix_list_1-0 start" start="1"><li class="c0"><span>Acknowledgement:
   This EULA is concluded between Atomical Software’s, LLC and the 
  end-user only. Atomical Software’s, LLC is solely responsible for the 
  Licensed Application and the content thereof.</span></li><li class="c0"><span>Scope
   of License: The license granted to the end-user for the Licensed 
  Application is limited to a non-transferable license to use the Licensed
   Application on any Android touch device that the end-user owns or 
  controls.</span></li><li class="c0"><span>Maintenance and Support: 
  Atomical Software’s, LLC is solely responsible for providing any 
  maintenance and support services with respect to the Licensed 
  Application, as specified in the EULA, or as required under applicable 
  law.</span></li><li class="c0"><span>Warranty: Atomical Software’s, LLC 
  is solely responsible for any product warranties, whether express or 
  implied by law, to the extent not effectively disclaimed.</span></li><li class="c0"><span>Product
   Claims: Atomical Software’s, LLC and the end user acknowledge that 
  Atomical Software’s, LLC, is responsible for addressing any claims of 
  the end-user or any third party relating to the Licensed Application or 
  the end-user’s possession and/or use of that Licensed Application, 
  including, but not limited to: (i) product liability claims; (ii) any 
  claim that the Licensed Application fails to conform to any applicable 
  legal or regulatory requirement; and (iii) claims arising under consumer
   protection or similar legislation. This EULA does not limit Atomical 
  Software’s, LLC’s liability to the end-user beyond what is permitted by 
  applicable law.</span></li><li class="c0"><span>Intellectual Property 
  Rights: Atomical Software’s, LLC and the end user acknowledge that, in 
  the event of any third party claim that the Licensed Application or the 
  end’s possession and use of that Licensed Application infringes that 
  third party’s intellectual property rights, Atomical Software’s, LLC, 
  will be solely responsible for the investigation, defense, settlement 
  and discharge of any such intellectual property infringement claim.</span></li><li class="c0"><span>Legal
   Compliance: The end-user represents and warrant that (i) he/she is not 
  located in a country that is subject to a U.S. Government embargo, or 
  that has been designated by the U.S. Government as a terrorist 
  supporting country; and (ii) he/she is not listed on any U.S. Government
   list of prohibited or restricted parties.</span></li><li class="c0"><span>Developer
   Name and Address: The developer may be reached at Atomical Software’s, 
  LLC, info@atomicalsoftwares.com to which any end-user questions, 
  complaints or claims with respect to the Licensed Application should be 
  directed.</span></li><li class="c0"><span>Reasonable advance notice (30 
  days) will be provided to current subscribers of an app before ceasing 
  making services available through an app developed by Atomical 
  Software’s, LLC, before Atomical Software’s, LLC take down an app or 
  remove it from current distribution due to: (a) an allegation or actual 
  infringement of any intellectual property right or right of publicity or
   privacy of any third party (b) an allegation or actual defamation, (c) 
  an allegation or determination that an app does not comply with 
  applicable law, (d) Atomical Software’s, LLC ceasing to do business, or 
  (e) Atomical Software’s, LLC filing a petition in bankruptcy, dissolving
   or otherwise failing or unable to pay your debts as they become due. In
   the case Atomical Software’s, LLC removes an app, merchants will be 
  given an opportunity to copy/export their data before the final ceasing 
  of service.</span></li><li class="c0"><span>An app may be removed at any
   time from the Clover App Market, if it is determined that the app: (i) 
  may infringe or otherwise violate the intellectual property rights or 
  any other rights of any third party; (ii) violates any applicable.</span></li><li class="c0"><span>Integracion
   Webhooks  App is available for a charge of $5.00. Developer 
  reserves the right to change at will. Existing customers (merchants) 
  pricing will be honored as long as they do not uninstall the app.</span></li><li class="c0"><span>Atomical Software’s, LLC does not use cookies of any kind.</span></li><li class="c0"><span>Atomical
   Software’s, LLC reserves the right to make changes to these Terms at 
  any time by publishing a revised version of these terms on the App 
  Market. The revised version of the terms will take effect from the time 
  at which it is first published. You will be subject to the Terms in 
  force at the time that you use the App Market. You are advised to check 
  the Terms from time to time for any updates or changes that may affect 
  you the end user.</span></li><li class="c0"><span>Atomical Software’s, 
  LLC does not represent or guarantee that any app developed by Atomical 
  Software’s, LLC will be free from loss, corruption, attack, viruses, 
  interference, hackings, or other security intrusions and Atomical 
  Software’s, LLC disclaims any liability relating thereto.</span></li><li class="c0"><span>These
   Terms will become effective on the date upon which you click to accept 
  them where this option is made available to you and will remain in 
  effect until terminated by either the end user or Atomical Software’s, 
  LLC</span></li><li class="c0"><span>Atomical Softwares LLC will collect 
  end-users (merchant’s) customers name &amp; contact number to properly 
  inform of the customer paying for the services/goods. This data is not 
  shared with any 3rd party providers or services, including analytic 
  services.</span></li><li class="c0"><span>End-user (merchant) is 
  responsible for creating &amp; publishing their own policies when 
  utilizing this “App” as a service to their customer including payments, 
  void &amp; refunds. </span></li><li class="c0"><span>Atomical Software’s
   LLC will not be responsible for misuse of this application which could 
  incur in the invalid data of their records.</span></li><li class="c0"><span>Atomical
   Software’s LLC will provide training &amp; support upon request without
   additional charges. Subject to Atomical Software’s LLC capacity to 
  handle multiple requests at once. We suggest always generating a ticket 
  at </span><span class="c1"><a class="c4" href="https://atomicalsoftwares.zendesk.com/">atomicalsoftwares.zendesk.com</a></span><span>.</span></li><li class="c0"><span>Merchants
   will be responsible to track &amp; maintain their customer data 
  accordingly, Atomical Software’s LLC will not take responsibility for 
  lost records (that happened due to end-user error) although we will 
  assist in any manner possible to satisfy the needs or guidance to our 
  end-users (merchants).</span></li><li class="c0"><span>Merchants are 
  responsible of payment confirmation. Atomical Softwares LLC provides the
   service as is. Failure of any system in the communication system from 
  any party can render a missed payment notification. This tool is 
  presented as convenience.</span></li><li class="c0"><span>Information obtained through the merchant’s customers database is not updated or maintained by Atomical Softwares LLC.</span></li><li class="c0"><span>Merchant’s
    are responsible for the safeguarding of their customer data at a 
  merchant level while using our services. These application terms or 
  policies cannot be transferred or combined with Atomical Softwares LLC 
  privacy policy or terms.</span></li>
           <!-- New terms starting from term 23 -->
                      <li class="c0"><span>El uso del código QR es una funcionalidad experimental.</span></li>
                      <li class="c0"><span>No se brindará apoyo por fallas en transacciones del código QR.</span></li>
                      <li class="c0"><span>El uso del código QR es una función no garantizada.</span></li>
                      <li class="c0"><span>Nos reservamos el derecho de proveer o culminar el apoyo al código QR.</span></li>
                      <li class="c0"><span>La integración está construida para utilizar el número de teléfono del cliente.</span></li>
                      <li class="c0"><span>El proceso por el número del cliente es el mismo que utilizar el código QR en aspecto de seguridad.</span></li>
                      <li class="c0"><span>Está en usted, el comerciante, orientar a sus clientes sobre el proceso de pago.</span></li>
                      <li class="c0"><span>No nos hacemos responsables por transacciones utilizando esta integración; no procesamos transacciones.</span></li>
                      <li class="c0"><span>Esta aplicación no está patrocinada ni respaldada por Evertec INC ni sus afiliados.</span></li>
                      <li class="c0"><span>No guardamos información sobre transacciones ni sobre sus clientes.</span></li>
                      <li class="c0"><span>El código QR proviene de Evertec; de fallar, no podemos proveer apoyo a un sistema propietario.</span></li>
                      <li class="c0"><span>Es de nuestro interés proveer la mejor experiencia con nuestra integración. Sin embargo, trabajamos con las limitaciones presentadas. El código QR es una de ellas.</span></li>
                      <li class="c0"><span>Todo comerciante se le ofrece 14 días de prueba, no se proveerán reembolsos. De mantener la aplicación instalada, usted está de acuerdo con estos términos y condiciones.</span></li>
               
  </ol><p class="c6"><span class="c2"></span></p><p class="c6"><span class="c2"></span></p></div>
              </div>
          </div>
      
</template>